export default {
  "form-content": "chK",
  "row": "chG",
  "numbering-format": "chr",
  "numbering-fields": "chb",
  "input-settings": "chy",
  "next-invoice-number": "chU",
  "spacing": "chj",
  "label-tooltip": "chW",
  "tooltip-icon": "chu",
  "customize-field": "cfS"
};
