export default {
  "supplier-selector-wrapper": "qla",
  "select-supplier": "qlH",
  "dropdown": "qlO",
  "iban": "qlm",
  "card": "qlp body-2",
  "add-btn": "qlx btn btn--square btn--icon-only",
  "archived-card": "qlw",
  "title": "qlN body-1",
  "archived-title": "qlv",
  "edit-supplier-tooltip": "qlo",
  "add-supplier": "qln btn btn--tertiary",
  "error": "qli caption"
};
