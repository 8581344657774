export default {
  "invoice-sidebar": "qIs",
  "body": "qIF",
  "box": "qIg",
  "box-header": "qID",
  "sidebar-box": "qIV",
  "border-top": "qIL",
  "row": "qIT",
  "greyed-out": "qIz",
  "strike-through": "qIa"
};
