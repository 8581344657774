export default {
  "row": "qkM",
  "greyed-out": "qkP",
  "supplier-name": "qkl",
  "cell": "qkX",
  "supplier": "qkC",
  "email-address": "qkk",
  "outstanding-balance": "qkJ",
  "quick-actions": "qks",
  "empty": "qkF",
  "supplier-container": "qkg",
  "supplier-avatar": "qkD",
  "grey-background": "qkV"
};
