export default {
  "sidepanel": "qCw",
  "close-icon": "qCN",
  "close-button": "qCv",
  "supplier-card": "qCo",
  "supplier-card-details": "qCn",
  "supplier-name": "qCi title-3 mb-8",
  "supplier-card-placeholder": "qCA",
  "box": "qCY",
  "box-element": "qCh",
  "related-invoices-title": "qCf",
  "placeholder-container": "qCK",
  "related-invoices-wrapper": "qCG",
  "related-invoice-placeholder": "qCr",
  "related-invoice-placeholder-inner": "qCb",
  "actions": "qCy",
  "currency": "qCU"
};
