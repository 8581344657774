export default {
  "header-cell": "qRX",
  "header-content": "qRC caption-bold",
  "active": "qRk",
  "empty": "qRJ",
  "align-right": "qRs",
  "request-type": "qRF",
  "requester": "qRg",
  "amount": "qRD"
};
