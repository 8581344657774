export default {
  "cell": "qCd",
  "cell-label-container": "qCI",
  "cell-label": "qCt",
  "overflow": "qCM",
  "sub-label": "qCP body-2",
  "overdue": "qCl",
  "file-name-warning": "qCX",
  "missing": "qCC",
  "warning-icon": "qCk",
  "flex-label": "qCJ",
  "with-separator": "qCs",
  "self-invoice-icon": "qCF",
  "tooltip-wrapper": "qCg"
};
