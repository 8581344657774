export default {
  "wrapper": "cuJ",
  "request-header": "cus",
  "request": "cuF",
  "scheduled": "cug",
  "scheduled-label": "cuD",
  "note": "cuV",
  "note-label": "cuL",
  "note-label-danger": "cuT",
  "note-content": "cuz",
  "status": "cua",
  "transfer-amount": "cuH"
};
