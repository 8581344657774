export default {
  "savings-account": "qBf",
  "closed": "qBK",
  "badge": "qBG",
  "subtitle": "qBr",
  "amount": "qBb",
  "processing": "qBy",
  "gain": "qBU",
  "active": "qBj",
  "progress": "qBW",
  "progress-bar": "qBu",
  "progress-text": "qES",
  "info": "qEc",
  "menu-item": "qEq body-2",
  "close-account": "qEZ"
};
