export default {
  "label-select": "qtz",
  "puce": "qta",
  "puce-wrapper": "qtH",
  "puce-container": "qtO",
  "readonly": "qtm",
  "label-display": "qtp",
  "label-list-name": "qtx body-1",
  "label-list-value": "qtw body-2"
};
