export default {
  "header-cell": "qkq",
  "first-col": "qkZ",
  "mid-col": "qkR",
  "outstanding-col": "qkQ",
  "header-content": "qke caption-bold",
  "active": "qkB",
  "quick-actions": "qkE",
  "empty": "qkd"
};
