export default {
  "form": "qll",
  "form-section": "qlX",
  "form-title": "qlC title2",
  "divider": "qlk",
  "row": "qlJ",
  "col": "qls",
  "actions": "qlF",
  "buttons": "qlg"
};
