export default {
  "header-cell": "cbt",
  "empty": "cbM",
  "header-type": "cbP",
  "header-requester": "cbl",
  "header-date": "cbX",
  "header-amount": "cbC",
  "header-approval": "cbk",
  "header-content": "cbJ caption-bold",
  "active": "cbs",
  "header-receipt": "cbF",
  "row-compact": "cbg",
  "row-sidebar": "cbD",
  "hidden": "cbV",
  "animated": "cbL",
  "fadein-item": "cbT",
  "animated-cell": "cbz"
};
