export default {
  "cell-content": "cui",
  "icon": "cuA",
  "status_check-m": "cuY",
  "description": "cuh body-1",
  "from": "cuf body-2",
  "from--abort": "cuK",
  "description--abort": "cuG",
  "title": "cur body-2"
};
