export default {
  "row": "cjD",
  "active": "cjV",
  "dot": "cjL",
  "red": "cjT",
  "green": "cjz",
  "yellow": "cja",
  "status": "cjH",
  "align-right": "cjO",
  "animated": "cjm",
  "fadein-item": "cjp",
  "cell": "cjx body-2",
  "cell-content": "cjw",
  "amount": "cjN body-2",
  "subtitle": "cjv caption",
  "padding-left": "cjo",
  "padding-right": "cjn",
  "note": "cji"
};
