export default {
  "insight-pill": "qlA",
  "interactive": "qlY",
  "focus": "qlh",
  "subtitle": "qlf caption",
  "hidden": "qlK",
  "missing-amounts-warning": "qlG",
  "totals": "qlr",
  "counter": "qlb title-3",
  "loading": "qly",
  "error": "qlU",
  "title": "qlj title-4"
};
