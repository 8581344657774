export default {
  "section-container": "qqq",
  "disclaimer-section": "qqZ",
  "trip-title": "qqR caption-bold mb-16",
  "google-link-container": "qqQ",
  "link-icon": "qqe",
  "trip-details": "qqB body-2",
  "trip-details-icon": "qqE",
  "trip-details-title": "qqd",
  "subtitle": "qqI caption-bold mb-16",
  "disclaimer": "qqt",
  "visualizer": "qqM"
};
