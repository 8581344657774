export default {
  "container": "ciq",
  "title-and-trash": "ciZ",
  "trash": "ciR",
  "quantity-amount": "ciQ",
  "flex-row": "cie",
  "quantity": "ciB",
  "quantity-input": "ciE",
  "border-left": "cid",
  "dropdown": "ciI",
  "item-row": "cit",
  "unit-price": "ciM",
  "price-input": "ciP",
  "hidden": "cil",
  "d-none": "ciX",
  "dropdown-option": "ciC",
  "list-row": "cik",
  "row-title": "ciJ",
  "row-price": "cis body-2",
  "row-internal-note": "ciF",
  "with-internal-note": "cig",
  "dropdown-content": "ciD",
  "select-item": "ciV"
};
