export default {
  "attachment-viewer": "cyR",
  "hide-sidebar": "cyQ",
  "sidebar": "cye",
  "header": "cyB",
  "header-top": "cyE",
  "header-icon": "cyd",
  "header-amount": "cyI",
  "infobox": "cyt",
  "vat": "cyM",
  "validation": "cyP"
};
