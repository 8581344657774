export default {
  "item-wrapper": "qMo",
  "title-sub-wrapper": "qMn",
  "icon-title-wrapper": "qMi",
  "icon": "qMA",
  "icon-wrapper": "qMY",
  "title": "qMh body-1",
  "subtitle": "qMf body-2",
  "amount": "qMK body-1"
};
