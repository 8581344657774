export default {
  "wrapper": "cKM",
  "tabs": "cKP",
  "tabs-nav": "cKl",
  "tabs-panel": "cKX",
  "tabs-panel-wrapper": "cKC",
  "list-head": "cKk",
  "list": "cKJ",
  "footer": "cKs",
  "reward-amount": "cKF",
  "rewarded": "cKg",
  "registered": "cKD",
  "disclaimer": "cKV",
  "empty-state-wrapper": "cKL",
  "empty-state": "cKT",
  "illustration": "cKz",
  "spinner": "cKa",
  "total-earned": "cKH"
};
