export default {
  "modal": "qdx",
  "container": "qdw",
  "form-container": "qdN",
  "preview-container": "qdv",
  "preview-content": "qdo",
  "switch-container": "qdn",
  "supplier-invoice-preview": "qdi",
  "header": "qdA",
  "footer": "qdY",
  "form-content": "qdh",
  "form-content-section": "qdf",
  "form-disclaimer": "qdK",
  "hidden": "qdG"
};
