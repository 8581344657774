export default {
  "invoice-sidebar": "cAP",
  "body": "cAl",
  "box": "cAX",
  "box-header": "cAC",
  "footer": "cAk",
  "footer-button": "cAJ",
  "footer-button-tooltip": "cAs",
  "sidebar-box": "cAF",
  "border-top": "cAg",
  "row": "cAD",
  "status-container": "cAV",
  "due-date-container": "cAL",
  "greyed-out": "cAT",
  "struck-through": "cAz",
  "overdue": "cAa",
  "primary-actions": "cAH cAF",
  "primary-action": "cAO",
  "danger-action": "cAm",
  "button-icon": "cAp",
  "button-label": "cAx body-2",
  "related-credit-note-title": "cAw caption-bold",
  "paid-at-placeholder": "cAN"
};
