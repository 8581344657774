export default {
  "header": "qqP",
  "header-type": "qql",
  "close": "qqX",
  "close-icon": "qqC",
  "header-infos": "qqk",
  "title": "qqJ body-1",
  "declined": "qqs",
  "canceled": "qqF",
  "approved": "qqg",
  "pending": "qqD",
  "date": "qqV body-2",
  "mileage-icon": "qqL",
  "distance-subtitle": "qqT body-2",
  "link-icon": "qqz",
  "container": "qqa",
  "picto": "qqH",
  "general": "qqO",
  "amount": "qqm",
  "calculation-container": "qqp",
  "counterparty": "qqx body-2",
  "infobox": "qqw"
};
