export default {
  "attachment-viewer": "qSr",
  "hide-sidebar": "qSb",
  "sidebar": "qSy",
  "header": "qSU",
  "header-top": "qSj",
  "header-icon": "qSW",
  "header-amount": "qSu",
  "infobox": "qcS",
  "vat": "qcc",
  "validation": "qcq"
};
