export default {
  "modal": "qXL",
  "action-container": "qXT",
  "similar-label": "qXz",
  "info-icon": "qXa",
  "container": "qXH",
  "subtitle": "qXO caption-bold",
  "error": "qXm",
  "item-container": "qXp"
};
