export default {
  "referral-invite": "cKO",
  "referral": "cKm",
  "illustration": "cKp",
  "steps": "cKx",
  "step": "cKw",
  "share-referral": "cKN",
  "choice": "cKv",
  "line": "cKo",
  "email": "cKn",
  "email-input": "cKi",
  "input-field--error": "cKA",
  "error-message": "cKY",
  "external-link-icon": "cKh"
};
