export default {
  "sidebar": "cAv",
  "body": "cAo",
  "form": "cAn",
  "form-section": "cAi",
  "form-title": "cAA title2",
  "row": "cAY",
  "payment-details": "cAh",
  "col": "cAf",
  "footer": "cAK",
  "footer-button": "cAG"
};
