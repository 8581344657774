export default {
  "gap-32": "cob",
  "fieldset": "coy",
  "date-picker": "coU",
  "with-performance-date": "coj",
  "mt-4": "coW",
  "clients": "cou",
  "dropdown-option": "cnS",
  "dropdown-content": "cnc",
  "select-client": "cnq",
  "client-option": "cnZ",
  "icon-tooltip": "cnR",
  "payment-details": "cnQ",
  "dates-container": "cne",
  "automatic-number-label": "cnB",
  "label": "cnE",
  "numbering-container": "cnd",
  "fix-width": "cnI",
  "customer-error": "cnt",
  "amount-text": "cnM",
  "header-text-field-container": "cnP",
  "header-text-field": "cnl",
  "header-text-field-close-button": "cnX",
  "mt-16": "cnC"
};
