export default {
  "header": "qMb",
  "subtitle": "qMy body-2",
  "date": "qMU",
  "subtitle-error": "qMj",
  "card": "qMW",
  "card-header": "qMu",
  "line-placeholder": "qPS",
  "post-closing-list": "qPc",
  "post-closing-container": "qPq",
  "post-closing-list-element": "qPZ",
  "free-trial-disclaimer": "qPR",
  "description": "qPQ body-2",
  "mw-100": "qPe",
  "addon-overview": "qPB",
  "addons-container": "qPE"
};
