export default {
  "header": "cyF",
  "header-type": "cyg",
  "close": "cyD",
  "close-icon": "cyV",
  "header-infos": "cyL",
  "title": "cyT body-1",
  "declined": "cyz",
  "canceled": "cya",
  "approved": "cyH",
  "pending": "cyO",
  "date": "cym body-2",
  "container": "cyp",
  "picto": "cyx",
  "picto-status": "cyw",
  "general": "cyN",
  "amount": "cyv",
  "counterparty": "cyo body-2",
  "initiator": "cyn",
  "initiator-avatar": "cyi",
  "avatar-image": "cyA",
  "initiator-id": "cyY",
  "name": "cyh body-2",
  "role": "cyf caption-bold",
  "infobox": "cyK",
  "account-infobox": "cyG cyK",
  "beneficiary-warning": "cyr caption-bold",
  "dates-wrapper": "cyb",
  "dates": "cyy",
  "recurrence": "cyU caption-bold",
  "arrow": "cyj",
  "notify-checkbox": "cyW"
};
