export default {
  "wrapper": "qde",
  "content": "qdB",
  "title": "qdE",
  "aside": "qdd",
  "description": "qdI",
  "faq-link": "qdt",
  "close-btn": "qdM",
  "container": "qdP",
  "mandate-field-list": "qdl",
  "mandate-field-item": "qdX",
  "mandate-field-item__dot": "qdC",
  "mandate-field-item__text": "qdk"
};
