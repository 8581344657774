export default {
  "row": "qsZ",
  "active": "qsR",
  "dot": "qsQ",
  "red": "qse",
  "green": "qsB",
  "yellow": "qsE",
  "align-right": "qsd",
  "animated": "qsI",
  "fadein-item": "qst",
  "cell": "qsM body-2",
  "cell-content": "qsP",
  "amount": "qsl body-1",
  "subtitle": "qsX caption",
  "status": "qsC",
  "cell-amount": "qsk"
};
