export default {
  "review": "qEO",
  "review-content": "qEm",
  "review-parameters": "qEp",
  "row": "qEx",
  "row__border": "qEw",
  "label": "qEN",
  "value": "qEv",
  "error": "qEo",
  "tooltip-icon": "qEn",
  "terms-link": "qEi"
};
