export default {
  "header-cell": "qJr",
  "header-content": "qJb caption-bold",
  "active": "qJy",
  "empty": "qJU",
  "type": "qJj",
  "requester": "qJW",
  "request-date": "qJu",
  "amount": "qsS",
  "receipt": "qsc",
  "quick-actions": "qsq"
};
