export default {
  "row": "qRi",
  "active": "qRA",
  "dot": "qRY",
  "red": "qRh",
  "green": "qRf",
  "yellow": "qRK",
  "status": "qRG",
  "align-right": "qRr",
  "animated": "qRb",
  "fadein-item": "qRy",
  "cell": "qRU body-2",
  "cell-content": "qRj",
  "amount": "qRW body-1",
  "subtitle": "qRu caption",
  "no-padding": "qQS"
};
