export default {
  "header-cell": "cGX",
  "header-content": "cGC caption-bold",
  "active": "cGk",
  "empty": "cGJ",
  "request-type": "cGs",
  "date": "cGF",
  "requester": "cGg",
  "amount": "cGD",
  "header-status": "cGV"
};
