export default {
  "header-cell": "qJv",
  "header-content": "qJo caption-bold",
  "active": "qJn",
  "empty": "qJi",
  "align-right": "qJA",
  "type": "qJY",
  "requester": "qJh",
  "request-date": "qJf",
  "amount": "qJK",
  "status": "qJG"
};
