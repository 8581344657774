export default {
  "payment-details": "ciL",
  "label-payment-conditions": "ciT",
  "dropdown-content": "ciz",
  "dropdown-option": "cia",
  "activate-sdd-container": "ciH",
  "activate-sdd-header": "ciO",
  "selector-toggle-sdd": "cim",
  "selector-toggle-content": "cip",
  "selector-toggle": "cix",
  "new-badge": "ciw",
  "new-badge-activation": "ciN",
  "mt-16": "civ"
};
