export default {
  "wrapper": "cKU",
  "tabs": "cKj",
  "tabs-nav": "cKW",
  "tabs-panel-wrapper": "cKu",
  "list-head": "cGS",
  "list": "cGc",
  "footer": "cGq",
  "reward-amount": "cGZ",
  "earned": "cGR",
  "pending": "cGQ",
  "empty-state-wrapper": "cGe",
  "empty-state": "cGB",
  "illustration": "cGE",
  "error-wrapper": "cGd",
  "error-illustration": "cGI",
  "error-description": "cGt",
  "spinner": "cGM",
  "total-earned": "cGP"
};
