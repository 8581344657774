export default {
  "container": "cfc",
  "content": "cfq",
  "input-row": "cfZ",
  "email-row": "cfR",
  "button-row": "cfQ",
  "btn-edit": "cfe",
  "input-column": "cfB",
  "form-btn-row": "cfE",
  "border-bottom": "cfd",
  "avatar-input": "cfI"
};
