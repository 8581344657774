export default {
  "header": "qZZ",
  "header-type": "qZR",
  "close": "qZQ",
  "close-icon": "qZe",
  "header-infos": "qZB",
  "title": "qZE body-1",
  "declined": "qZd",
  "canceled": "qZI",
  "approved": "qZt",
  "date": "qZM body-2",
  "container": "qZP",
  "picto": "qZl",
  "request": "qZX",
  "picto-status": "qZC",
  "check": "qZk",
  "stop": "qZJ",
  "warning": "qZs",
  "general": "qZF",
  "amount": "qZg",
  "counterparty": "qZD body-2",
  "initiator": "qZV",
  "initiator-avatar": "qZL",
  "avatar-image": "qZT",
  "initiator-id": "qZz",
  "name": "qZa body-2",
  "role": "qZH caption-bold",
  "infobox": "qZO",
  "account-infobox": "qZm qZO",
  "beneficiary-warning": "qZp caption-bold",
  "dates-wrapper": "qZx",
  "dates": "qZw",
  "recurrence": "qZN caption-bold",
  "arrow": "qZv",
  "notify-checkbox": "qZo"
};
