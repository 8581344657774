export default {
  "row": "cub",
  "empty": "cuy",
  "active": "cuU",
  "animated": "cuj",
  "fadein-item": "cuW",
  "cell": "cuu body-2",
  "cell-content": "qSS",
  "cell-amount": "qSc",
  "cell-content-amount": "qSq body-1"
};
