export default {
  "row": "qQM",
  "active": "qQP",
  "dot": "qQl",
  "red": "qQX",
  "green": "qQC",
  "yellow": "qQk",
  "status": "qQJ",
  "align-right": "qQs",
  "animated": "qQF",
  "fadein-item": "qQg",
  "cell": "qQD body-2",
  "cell-content": "qQV",
  "amount": "qQL body-2",
  "subtitle": "qQT caption",
  "padding-left": "qQz",
  "padding-right": "qQa",
  "note": "qQH"
};
