export default {
  "mileage-validation": "qqA",
  "actions": "qqY",
  "action-btn": "qqh",
  "approve-btn": "qqf",
  "request-mileage-validation": "qqK",
  "account-selector": "qqG",
  "has-error": "qqr",
  "funds-disclaimer": "qqb",
  "account-options": "qqy",
  "account-balance": "qqU",
  "warnings": "qqj"
};
