export default {
  "bank-account-option": "qsJ",
  "row": "qss",
  "active": "qsF",
  "animated": "qsg",
  "fadein-item": "qsD",
  "cell": "qsV body-2",
  "cell-content": "qsL",
  "subtitle": "qsT caption",
  "no-padding": "qsz",
  "empty": "qsa",
  "note": "qsH",
  "cell-content-amount": "qsO body-1",
  "cell-content-receipt": "qsm body-1",
  "cell-quick-actions": "qsp",
  "account-selector": "qsx",
  "cell-type": "qsw"
};
