export default {
  "modal-wrapper": "chD",
  "spinner-container": "chV",
  "spinner": "chL",
  "container": "chT",
  "tabs-item": "chz",
  "form-container": "cha",
  "fieldset": "chH",
  "preview-container": "chO",
  "switch-container": "chm",
  "pdf-preview-container": "chp",
  "form-content": "chx",
  "row": "chw",
  "numbering-format": "chN",
  "numbering-fields": "chv",
  "input-settings": "cho",
  "next-invoice-number": "chn",
  "spacing": "chi",
  "label-tooltip": "chA",
  "tooltip-icon": "chY",
  "customize-field": "chh",
  "button-container-footer": "chf"
};
