export default {
  "container": "cYD",
  "form-container": "cYV",
  "content": "cYL",
  "fields": "cYT",
  "input-settings": "cYz",
  "current-year": "cYa",
  "next-invoice-number": "cYH",
  "preview-container": "cYO",
  "actions": "cYm",
  "close-button": "cYp btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cYx",
  "preview-image": "cYw"
};
