export default {
  "modal": "chR",
  "action-container": "chQ",
  "similar-label": "che",
  "info-icon": "chB",
  "container": "chE",
  "subtitle": "chd caption-bold",
  "error": "chI",
  "item-container": "cht"
};
