export default {
  "header-cell": "qRm",
  "header-content": "qRp caption-bold",
  "active": "qRx",
  "empty": "qRw",
  "request-type": "qRN",
  "status": "qRv",
  "note": "qRo",
  "amount": "qRn"
};
