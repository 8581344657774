export default {
  "card-sidebar-header": "qSg",
  "content": "qSD",
  "header-type": "qSV",
  "close": "qSL",
  "close-icon": "qST",
  "header-infos": "qSz",
  "title": "qSa body-1",
  "danger": "qSH",
  "success": "qSO",
  "warning": "qSm",
  "date": "qSp body-2",
  "card-image-container": "qSx",
  "card-image": "qSw",
  "info-container": "qSN",
  "spending-reason": "qSv",
  "spending-reason-label": "qSo body-2 mr-8",
  "spending-reason-note": "qSn body-2"
};
