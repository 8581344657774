export default {
  "subtitle": "cWP",
  "green-text": "cWl",
  "balance-subtitle": "cWX",
  "options": "cWC",
  "shadow": "cWk",
  "menu-item": "cWJ body-2",
  "close-account": "cWs",
  "footer": "cWF",
  "progress": "cWg",
  "progress-bar": "cWD",
  "progress-bar-text": "cWV",
  "progress-active": "cWL"
};
