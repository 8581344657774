export default {
  "item": "qXx",
  "details": "qXw",
  "flex": "qXN",
  "header": "qXv qXN",
  "flex-no-gap": "qXo qXN",
  "file-name": "qXn",
  "label": "qXi body-2",
  "self-icon": "qXA",
  "with-separator": "qXY",
  "dimmed": "qXh"
};
