export default {
  "document": "cYy",
  "document-details": "cYU",
  "document-details-amount": "cYj body-2",
  "negative": "cYW",
  "positive": "cYu",
  "rejected": "chS",
  "document-icon": "chc",
  "document-details-link": "chq body-link",
  "canceled": "chZ"
};
