export default {
  "header": "crX",
  "header-type": "crC",
  "close": "crk",
  "close-icon": "crJ",
  "header-infos": "crs",
  "title": "crF body-1",
  "declined": "crg",
  "canceled": "crD",
  "approved": "crV",
  "pending": "crL",
  "date": "crT body-2",
  "mileage-icon": "crz",
  "distance-subtitle": "cra body-2",
  "link-icon": "crH",
  "container": "crO",
  "picto": "crm",
  "general": "crp",
  "amount": "crx",
  "calculation-container": "crw",
  "counterparty": "crN body-2",
  "infobox": "crv"
};
