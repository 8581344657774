export default {
  "container": "ciY",
  "text-content": "cih",
  "text-wrapper": "cif",
  "actions-container": "ciK",
  "illustration": "ciG",
  "feature-tour-link": "cir",
  "link-illustration": "cib",
  "lottie": "ciy"
};
