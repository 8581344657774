export default {
  "request-expense-report-validation": "qch",
  "account-selector": "qcf",
  "has-error": "qcK",
  "actions": "qcG",
  "action-btn": "qcr",
  "action-btn-success": "qcb",
  "warnings": "qcy",
  "top-border": "qcU",
  "account-selector-subtitle": "qcj",
  "action-btn-right": "qcW",
  "funds-disclaimer": "qcu",
  "account-options": "qqS",
  "account-balance": "qqc"
};
