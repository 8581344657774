export default {
  "refunds-total": "qPl",
  "switch-recurrence": "qPX",
  "flex-wrapper": "qPC",
  "promo-code": "qPk",
  "promo-code-total": "qPJ",
  "others": "qPs",
  "others-total": "qPF",
  "subscription-detail": "qPg",
  "total-vat": "qPD",
  "fee-description": "qPV"
};
