export default {
  "header": "cUF",
  "header-type": "cUg",
  "close": "cUD",
  "close-icon": "cUV",
  "header-infos": "cUL",
  "title": "cUT body-1",
  "declined": "cUz",
  "canceled": "cUa",
  "approved": "cUH",
  "pending": "cUO",
  "date": "cUm body-2",
  "mileage-icon": "cUp",
  "distance-subtitle": "cUx body-2",
  "link-icon": "cUw",
  "container": "cUN",
  "picto": "cUv",
  "general": "cUo",
  "amount": "cUn",
  "calculation-container": "cUi",
  "counterparty": "cUA body-2",
  "infobox": "cUY"
};
