export default {
  "row": "qtr",
  "disabled": "qtb",
  "cell": "qty body-2",
  "cell-link": "qtU",
  "cell-content": "qtj",
  "item-truncate-text": "qtW",
  "item-name-cell": "qtu",
  "item-account-cell": "qMS",
  "icon": "qMc",
  "icon-download": "qMq"
};
