export default {
  "header-cell": "cfC",
  "empty": "cfk",
  "header-content": "cfJ caption-bold",
  "active": "cfs",
  "align-right": "cfF",
  "col-8": "cfg",
  "col-7": "cfD",
  "col-5": "cfV",
  "col-4": "cfL"
};
