export default {
  "card-details-container": "qSM",
  "title": "qSP caption-bold mb-16",
  "section": "qSl",
  "divider": "qSX",
  "top-divider": "qSC",
  "content": "qSk body-2",
  "description": "qSJ",
  "justify-end": "qSs",
  "icon": "qSF ml-8"
};
