export default {
  "content": "cWK",
  "balance": "cWG",
  "duration": "cWr",
  "green-text": "cWb",
  "balance-subtitle": "cWy",
  "balance-duration": "cWU",
  "footer": "cWj",
  "progress": "cWW",
  "progress-bar": "cWu",
  "progress-bar-text": "cuS",
  "progress-active": "cuc"
};
