export default {
  "sections": "qeI",
  "actions": "qet",
  "search-input": "qeM",
  "no-upload": "qeP",
  "new-link": "qel body-2",
  "between-links": "qeX",
  "action-bar": "qeC",
  "empty-state": "qek"
};
