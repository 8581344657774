export default {
  "request-transfer-validation": "qey",
  "account-selector": "qeU",
  "has-error": "qej",
  "actions": "qeW",
  "action-btn": "qeu",
  "warnings": "qBS",
  "top-border": "qBc",
  "account-selector-subtitle": "qBq",
  "action-btn-right": "qBZ",
  "funds-disclaimer": "qBR",
  "account-options": "qBQ",
  "account-balance": "qBe",
  "request-multi-transfer-validation": "qBB",
  "wrapper": "qBE",
  "warning-message-row": "qBd",
  "multi-transfer-align": "qBI",
  "button-approve": "qBt",
  "button-approve-spinner": "qBM",
  "button-decline": "qBP"
};
