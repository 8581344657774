export default {
  "invoice-sidebar": "cAr",
  "body": "cAb",
  "box": "cAy",
  "box-header": "cAU",
  "footer": "cAj",
  "footer-button": "cAW",
  "sidebar-box": "cAu",
  "border-top": "cYS",
  "row": "cYc",
  "status-container": "cYq",
  "due-date-container": "cYZ",
  "mark-as-btn": "cYR",
  "primary-actions": "cYQ cAu",
  "primary-action": "cYe body-1",
  "button-icon": "cYB"
};
