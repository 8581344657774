export default {
  "row": "cba body-1",
  "active": "cbH",
  "cell": "cbO",
  "empty": "cbm",
  "ellipsis": "cbp",
  "text-secondary": "cbx",
  "transfer-type": "cbw",
  "transfer-type__icon": "cbN",
  "quick-actions": "cbv",
  "amount": "cbo",
  "cell-approve": "cbn",
  "account-select": "cbi",
  "row-compact": "cbA",
  "cell-amount": "cbY",
  "cell-content-receipt": "cbh body-1",
  "cell-date": "cbf",
  "row-sidebar": "cbK",
  "hidden": "cbG",
  "animated": "cbr",
  "fadein-item": "cbb",
  "animated-cell": "cby",
  "note": "cbU"
};
