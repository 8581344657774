export default {
  "progress-container": "qFJ",
  "dashed-line": "qFs",
  "solid-line": "qFF",
  "circle-container": "qFg",
  "circle": "qFD",
  "current-step-dot": "qFV",
  "last-step-dot": "qFL",
  "success-icon": "qFT",
  "rejected-icon": "qFz"
};
