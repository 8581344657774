export default {
  "item": "chM",
  "details": "chP",
  "flex": "chl",
  "header": "chX chl",
  "flex-no-gap": "chC chl",
  "file-name": "chk",
  "label": "chJ body-2",
  "self-icon": "chs",
  "with-separator": "chF",
  "dimmed": "chg"
};
