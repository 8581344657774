export default {
  "details": "qPm",
  "details-header": "qPp",
  "details-content": "qPx",
  "details-footer": "qPw",
  "invoice-status-container": "qPN body-2",
  "invoice-payment-date": "qPv",
  "overdue": "qPo",
  "details-list": "qPn",
  "details-list-item": "qPi",
  "details-list-item-label": "qPA body-2",
  "details-list-item-value": "qPY",
  "border-top": "qPh",
  "description": "qPf",
  "description-value": "qPK",
  "edit-button": "qPG body-2",
  "edit-icon": "qPr",
  "badge-union": "qPb",
  "edit-description": "qPy"
};
