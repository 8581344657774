export default {
  "row": "cGh",
  "icon": "cGf",
  "cell": "cGK",
  "cell-amount": "cGG",
  "cell-status": "cGr",
  "cell-date": "cGb",
  "cell-content": "cGy",
  "align-right": "cGU"
};
