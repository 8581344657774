export default {
  "wrapper": "qeN",
  "header": "qev mb-48",
  "form-title": "qeo title-2 mb-16",
  "form-subtitle": "qen body-2",
  "form": "qei",
  "section": "qeA mb-48",
  "section-title": "qeY title-3 mb-16",
  "form-footer": "qeh",
  "footer-btn": "qef",
  "transfer-form": "qeK"
};
