export default {
  "insight-pill": "qlW",
  "insight-pill-figure": "qlu",
  "insight-pill-renderer": "qXS",
  "loading": "qXc",
  "counter": "qXq",
  "error": "qXZ",
  "insight-pill-label": "qXR",
  "insight-pill-subtitle": "qXQ caption",
  "hidden": "qXe",
  "missing-amounts-warning": "qXB",
  "missing-amounts": "qXE"
};
