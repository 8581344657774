export default {
  "numbering-container": "cni",
  "automatic-number-label": "cnA",
  "icon-tooltip": "cnY",
  "fix-width": "cnh",
  "dates-container": "cnf",
  "with-performance-date": "cnK",
  "date-picker": "cnG",
  "label": "cnr",
  "header-text-field-container": "cnb",
  "mt-16": "cny",
  "header-text-field": "cnU",
  "header-text-field-close-button": "cnj"
};
