export default {
  "header": "qcd",
  "header-type": "qcI",
  "close": "qct",
  "close-icon": "qcM",
  "header-infos": "qcP",
  "title": "qcl body-1",
  "declined": "qcX",
  "canceled": "qcC",
  "approved": "qck",
  "pending": "qcJ",
  "date": "qcs body-2",
  "container": "qcF",
  "picto": "qcg",
  "picto-status": "qcD",
  "general": "qcV",
  "amount": "qcL",
  "counterparty": "qcT body-2",
  "initiator": "qcz",
  "initiator-avatar": "qca",
  "avatar-image": "qcH",
  "initiator-id": "qcO",
  "name": "qcm body-2",
  "role": "qcp caption-bold",
  "infobox": "qcx",
  "account-infobox": "qcw qcx",
  "beneficiary-warning": "qcN caption-bold",
  "dates-wrapper": "qcv",
  "dates": "qco",
  "recurrence": "qcn caption-bold",
  "arrow": "qci",
  "notify-checkbox": "qcA"
};
