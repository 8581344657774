export default {
  "row": "cGL",
  "active": "cGT",
  "animated": "cGz",
  "fadein-item": "cGa",
  "cell": "cGH",
  "cell-content": "cGO",
  "row-sidebar": "cGm",
  "empty": "cGp",
  "cell-amount": "cGx",
  "subtitle": "cGw caption",
  "hidden": "cGN",
  "cell-approve": "cGv",
  "quick-actions": "cGo",
  "account-select": "cGn",
  "note": "cGi",
  "cell-content-amount": "cGA body-1",
  "cell-status": "cGY"
};
