export default {
  "wrapper": "qeJ",
  "beneficiary": "qes",
  "name": "qeF body-1",
  "beneficiary-email": "qeg body-2",
  "dropdown-icon": "qeD",
  "trusted": "qeV",
  "account-number": "qeL flex body-2",
  "account-icon": "qeT",
  "m-chip": "qez",
  "dropdown": "qea body-2",
  "delete-option": "qeH",
  "beneficiary-name": "qeO"
};
