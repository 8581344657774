export default {
  "team-card": "qFS",
  "card-link": "qFc",
  "shadow": "qFq",
  "history-button": "qFZ",
  "cards-button": "qFR",
  "menu-item": "qFQ",
  "subtitle": "qFe",
  "delete-team": "qFB"
};
