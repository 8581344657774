export default {
  "iban": "qQb",
  "checkbox": "qQy",
  "toggle-content": "qQU",
  "beneficiary-name": "qQj",
  "category": "qQW",
  "beneficiary-email": "qQu",
  "beneficiary-form": "qeS",
  "beneficiary-email-container": "qec"
};
