export default {
  "row": "cfa",
  "cell": "cfH",
  "greyed-out": "cfO",
  "struck-through": "cfm",
  "flex-cell": "cfp",
  "overdue": "cfx",
  "align-right": "cfw",
  "customer-name-container": "cfN",
  "icon": "cfv",
  "warning": "cfo",
  "with-separator": "cfn",
  "align-center": "cfi"
};
