export default {
  "row": "qsN",
  "icon": "qsv",
  "cell": "qso",
  "cell-date": "qsn",
  "cell-amount": "qsi",
  "cell-status": "qsA",
  "cell-content": "qsY",
  "align-right": "qsh"
};
