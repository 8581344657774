export default {
  "referral-invite-new": "cfb",
  "referral": "cfy",
  "center": "cfU",
  "illustration": "cfj",
  "share-referral": "cfW",
  "choice": "cfu",
  "line": "cKS",
  "email": "cKc",
  "email-input": "cKq",
  "input-field--error": "cKZ",
  "error-message": "cKR",
  "external-link-icon": "cKQ"
};
