export default {
  "section-container": "cUd",
  "disclaimer-section": "cUI",
  "trip-title": "cUt caption-bold mb-16",
  "google-link-container": "cUM",
  "link-icon": "cUP",
  "trip-details": "cUl body-2",
  "trip-details-icon": "cUX",
  "trip-details-title": "cUC",
  "subtitle": "cUk caption-bold mb-16",
  "disclaimer": "cUJ",
  "visualizer": "cUs"
};
