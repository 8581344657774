export default {
  "row": "qQc",
  "active": "qQq",
  "animated": "qQZ",
  "fadein-item": "qQR",
  "cell": "qQQ body-2",
  "cell-content": "qQe",
  "subtitle": "qQB caption",
  "no-padding": "qQE",
  "note": "qQd",
  "cell-amount": "qQI",
  "cell-content-amount": "qQt body-1"
};
