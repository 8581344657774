export default {
  "section-container": "crR",
  "disclaimer-section": "crQ",
  "trip-title": "cre caption-bold mb-16",
  "google-link-container": "crB",
  "link-icon": "crE",
  "trip-details": "crd body-2",
  "trip-details-icon": "crI",
  "trip-details-title": "crt",
  "subtitle": "crM caption-bold mb-16",
  "disclaimer": "crP",
  "visualizer": "crl"
};
