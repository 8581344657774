export default {
  "section": "cYP",
  "header-section": "cYl",
  "header-title": "cYX",
  "header-item": "cYC",
  "item": "cYk",
  "center-align": "cYJ",
  "content-gap": "cYs",
  "cta-footer": "cYF",
  "section-content": "cYg"
};
