/* import __COLOCATED_TEMPLATE__ from './quick-actions.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { GENERIC_IBAN, INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { ErrorInfo } from 'qonto/utils/error-info';
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierInvoicesQuickActionsComponent extends Component {
  InstructionalTooltip = InstructionalTooltip;

  @service abilities;
  @service beneficiariesManager;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service supplierInvoicesManager;

  @reads('organizationManager.organization') organization;

  get shouldDisplayPayLaterTooltip() {
    return variation('feature-boolean-si-pay-later-tooltip');
  }

  get isInbox() {
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay].includes(this.args.invoice?.status);
  }

  get isSwift() {
    let { totalAmount } = this.args.invoice;

    return totalAmount && this.args.invoice.totalAmount?.currency !== CURRENCIES.default;
  }

  get canUpdateInvoice() {
    return [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay, INVOICE_STATUSES.paid].includes(
      this.args.invoice?.status
    );
  }

  get showArchiveAction() {
    return (
      this.args.invoice?.status === INVOICE_STATUSES.toReview &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get showUnarchiveAction() {
    return (
      this.args.invoice?.status === INVOICE_STATUSES.archived &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get showDeleteQuickAction() {
    return (
      this.canUpdateInvoice &&
      this.abilities.can('delete supplierInvoice') &&
      !this.args.hasSelfInvoice
    );
  }

  get canUpdateInvoiceStatus() {
    let { dueDate, supplierName, status, totalAmount } = this.args.invoice || {};

    return (
      status === INVOICE_STATUSES.paid || Boolean(dueDate && supplierName && totalAmount?.value)
    );
  }

  get isPayInvoiceTooltipDisabled() {
    return this.canUpdateInvoiceStatus && !this.args.invoice?.hasDuplicates;
  }

  get isPayByTransferQuickActionDisabled() {
    return (
      this.checkTransferLimitsTask.isRunning ||
      this.isPayByTransferDisabled ||
      this.args.invoice?.hasDuplicates ||
      (this.isSwift && this.organization.hasInternationalOutFeature)
    );
  }

  get isRequestTransferQuickActionDisabled() {
    return (
      this.checkTransferLimitsTask.isRunning ||
      !this.canUpdateInvoiceStatus ||
      this.args.invoice?.hasDuplicates
    );
  }

  get matchTransactionCopy() {
    return this.args.invoice.status === INVOICE_STATUSES.paid
      ? this.intl.t('supplier-invoices.cta.match-transaction')
      : this.intl.t('supplier-invoices.cta.mark-as-paid');
  }

  get showUpdateActions() {
    return (
      this.abilities.can('update supplierInvoice') &&
      [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay, INVOICE_STATUSES.paid].includes(
        this.args.invoice?.status
      )
    );
  }

  get hasTransferWarnings() {
    return this.checkTransferLimitsTask.lastSuccessful?.value;
  }

  get canPayByTransfer() {
    return this.abilities.can('create transfer') && (!this.hasTransferWarnings || this.isSwift);
  }

  get isPayByTransferDisabled() {
    return (this.isSwift && this.hasTransferWarnings) || !this.canUpdateInvoiceStatus;
  }

  get payByTransferTooltip() {
    if (this.isPayByTransferDisabled) {
      return this.intl.t('supplier-invoices.preview.quick-actions-pay-by-transfer-tooltip');
    }

    if (this.args.invoice?.hasDuplicates) {
      return this.intl.t(
        'supplier-invoices.table.duplicates.quick-actions.pay-by-transfer-not-allowed-tooltip'
      );
    }
  }

  get requestTransferTooltip() {
    if (!this.canUpdateInvoiceStatus) {
      return this.isSwift
        ? this.intl.t('supplier-invoices.preview.quick-actions-swift-request-transfer-tooltip')
        : this.intl.t('supplier-invoices.preview.quick-actions-request-transfer-tooltip');
    }

    if (this.args.invoice?.hasDuplicates) {
      return this.intl.t(
        'supplier-invoices.table.duplicates.quick-actions.request-transfer-not-allowed-tooltip'
      );
    }
  }

  @action
  toggleMenu(showMenu) {
    this.args.onToggleMenu?.(showMenu);

    if (showMenu && !this.hasTransferWarnings) {
      this.checkTransferLimitsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.withScope(scope => {
              if (errorInfo.httpStatus === 422 && error.errors?.length) {
                error.errors.forEach((e, i) => {
                  scope.setContext(`error_${i}`, e);
                });
              }

              this.sentry.captureException(error);
            });
          }
        });
    }
  }

  @action
  openInvoiceEdition() {
    this.segment.track('supplier-invoices_edit_clicked', { page: this.args.invoice.status });
    this.router.transitionTo('supplier-invoices.show', this.args.invoice.id, {
      queryParams: { edit: true },
    });
  }

  triggerDeleteInvoiceTask = dropTask(async () => {
    let { invoice } = this.args;

    this.segment.track('supplier-invoices_delete_clicked', {
      page: invoice.status,
      action_type: 'single_item',
      is_non_financial_document: invoice.isAttachmentNonFinancial,
    });

    await this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.delete-modal.title'),
      description: this.intl.t('supplier-invoices.delete-modal.subtitle'),
      confirm: this.intl.t('btn.delete'),
      cancel: this.intl.t('btn.cancel'),
      confirmTask: this.supplierInvoicesManager.confirmDeleteInvoiceTask,
      invoice: this.args.invoice,
      onDeleteInvoice: this.args.onDeleteInvoice,
    });
  });

  downloadInvoiceAttachmentTask = dropTask(async () => {
    let attachment = await this._fetchAttachment(this.args.invoice);

    this.segment.track('supplier-invoices_download_clicked', { page: this.args.invoice.status });
    window.open(attachment.downloadUrl);
  });

  markAsPaidTask = dropTask(async () => {
    let attachment = await this._fetchAttachment(this.args.invoice);
    let feedback =
      this.args.invoice.status === INVOICE_STATUSES.paid
        ? this.intl.t('supplier-invoices.success-toast.matched-transaction')
        : this.intl.t('supplier-invoices.success-toast.mark-as-paid');

    this.segment.track('supplier-invoices_mark-as-paid_clicked', {
      page: this.args.invoice.status,
    });
    this.modals.open('supplier-invoices/transactions-modal', {
      isFullScreenModal: true,
      attachment,
      invoice: this.args.invoice,
      onCloseModal: () => {
        this.toastFlashMessages.toastSuccess(feedback);
        this.router.transitionTo('supplier-invoices.index');
      },
    });
  });

  checkTransferLimitsTask = restartableTask(async () => {
    if (
      !this.canPayByTransfer ||
      this.isPayByTransferDisabled ||
      !this.isInbox ||
      !parseFloat(this.args.invoice.totalAmount?.value) > 0
    )
      return;

    let { invoiceNumber, supplierName, totalAmount } = this.args.invoice;
    let { value, currency } = totalAmount;
    let amountDetails = this.isSwift
      ? {
          localAmountCurrency: currency,
          localAmount: value,
        }
      : {
          amountCurrency: currency,
          amount: value,
        };

    let transfer = this.store.createRecord('transfer', {
      bankAccount: this.organization.mainAccount,
      organization: this.organization,
      fx: this.isSwift,
      name: supplierName || 'name',
      iban: GENERIC_IBAN,
      activityTag: 'other_expense',
      reference: invoiceNumber || 'reference',
      ...amountDetails,
    });

    let { warnings, market } = await (this.isSwift ? transfer.confirmFx() : transfer.confirm());

    return (
      warnings?.some(warning => SPEND_LIMITS_WARNINGS.includes(warning)) || market?.opened === false
    );
  });

  payByTransferTask = dropTask(async () => {
    let { id } = this.args.invoice || {};

    let queryParams = { origin: TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES, supplierInvoiceId: id };
    this.segment.track('supplier-invoices_pay-by-transfer_clicked', {
      page: INVOICE_STATUSES.inbox,
    });

    if (this.isSwift) {
      // If the organization has access to the revamped international transfer flow,
      // we don't want to transition to the deprecated flow.
      // It should not reach this point, as the quick action should be disabled,
      // but we're adding this check as a safety net.
      if (!this.organization.hasInternationalOutFeature) {
        this.flowLinkManager.transitionTo({
          name: 'fx-transfer',
          stepId: 'beneficiaries',
          queryParams,
        });
      }
    } else {
      let initialStepId = await this._getSEPATransferInitialStepId(this.args.invoice);
      this.flowLinkManager.transitionTo({
        name: 'sepa-transfer',
        stepId: initialStepId,
        queryParams,
      });
    }
  });

  @action
  requestTransfer() {
    this.segment.track('supplier-invoices_request-transfer_clicked');
    this.router.transitionTo('requests.transfers.new', {
      queryParams: {
        supplierInvoiceId: this.args.invoice.id,
      },
    });
  }

  @action
  archive(id) {
    this.segment.track('supplier-invoices_archive_clicked', {
      origin: 'invoices_list',
      action_type: 'single_item',
    });
    this.modals.open('supplier-invoices/archive-popup', {
      id,
    });
  }

  @action
  unarchive(id) {
    this.segment.track('supplier-invoices_unarchive_clicked', { origin: 'invoices_list' });
    this.modals.open('supplier-invoices/unarchive-popup', { id });
  }

  async _fetchAttachment(invoice) {
    let attachmentId = invoice.belongsTo('attachment').id();
    return await this.store.findRecord('attachment', attachmentId);
  }

  async _getSEPATransferInitialStepId(invoice) {
    let { iban, supplierName } = invoice || {};

    if (iban) {
      let beneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        this.organizationManager.organization.id,
        iban
      );
      if (beneficiary && beneficiary.name === supplierName) return 'details';
    }

    return 'add-supplier';
  }
}
